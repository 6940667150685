import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Header from "../components/ui/header"
import SEO from "../components/common/seo"
import HeroBanner from "../components/page/hero-banner"
import { convertToInternalUrl } from "../utils/global-util"
import styled from "styled-components"
import vars from "../styles/vars"

function Sitemap(props) {
  const data = useStaticQuery(graphql`
    query SitemapQuery {
      allWordpressPage(
        sort: { fields: [wordpress_parent, title], order: ASC }
        filter: { slug: { nin: ["home", "thank-you"] } }
      ) {
        nodes {
          id
          title
          path
        }
      }
      allWordpressPost {
        nodes {
          id
          path
          title
        }
      }
    }
  `)

  const pages = data.allWordpressPage.nodes || []
  const posts = data.allWordpressPost.nodes || []

  return (
    <StyledSitemap>
      <Header />
      <SEO title="Sitemap | Digitisable" />
      <HeroBanner title="Sitemap" data={[]} />
      <section className="container">
        <div className="row">
          <div className="col-12">
            <h2>Pages</h2>
          </div>
          <div className="col-12 col-md-6 col-xl-4 mb-2">
            <Link to="/">Home</Link>
          </div>
          {pages.map(page => (
            <div key={page.id} className="col-12 col-md-6 col-xl-4 mb-2">
              <Link to={convertToInternalUrl(page.path)}>{page.title}</Link>
            </div>
          ))}
        </div>

        {posts.length > 0 && (
          <div className="row">
            <div className="col-12">
              <h2>Blog Posts</h2>
            </div>
            {posts.map(post => (
              <div key={post.id} className="col-12 mb-2">
                <Link to={convertToInternalUrl(post.path)}>{post.title}</Link>
              </div>
            ))}
          </div>
        )}
      </section>
    </StyledSitemap>
  )
}

export default Sitemap

const StyledSitemap = styled.div`
  a {
    color: ${vars.black};
    transition: ease-in-out 200ms;
    &:hover {
      color: ${vars.primaryColour};
    }
  }
`
